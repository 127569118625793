<script setup lang="ts">
import WapNative from './wap-native.vue';

const props = defineProps<{
    spaceKey: string,
}>()

import { onMounted } from 'vue';
let timer: any = null, countdown: number = 3;
const writeCloseDom = () => {
    let dom = document.getElementsByClassName('close-btn')[0];
    let child = dom.childNodes;
    for (let i = child.length - 1; i >= 0; i--) {
        dom.removeChild(child[i]);
    }
    if (countdown > 0) {
        let span = document.createElement('span');
        span.innerHTML = countdown + '';
        dom.appendChild(span);
    } else {
        let img = document.createElement('img');
        img.src = 'https://static.zookingsoft.com/h5/sdk/close_btn.png';
        dom.appendChild(img);
    }
}
onMounted(() => {
    writeCloseDom();
    timer = setInterval(() => {
        countdown -= 1;
        writeCloseDom();
        if (countdown <= 0) {
            clearInterval(timer);
            timer = null;
        }
    }, 1000);
})
const hideInterWrap = () => {
    let interWrap = document.getElementsByClassName('wap-inter-native-wrapper')[0];
    interWrap.setAttribute('style', 'width:0;height:0;');
}
const emitAdStage = defineEmits(['adclose', 'aderror', 'adclick']);
const handleClose = () => {
    if (countdown > 0) return;
    hideInterWrap();
    emitAdStage('adclose');
}
const handleError = () => {
    hideInterWrap();
    emitAdStage('aderror');
}
const handleClick = () => {
    emitAdStage('adclick');
}
</script>

<template>
    <div class="wap-inter-native-wrapper" @touchstart.stop @touchmove.stop @touchend.stop>
        <div class="close-wrap">
            <div class="close-btn" @click="handleClose">
                <span v-if="countdown > 0">{{ countdown }}</span>
                <img v-else src="" />
            </div>
        </div>
        <div class="native-wrap">
            <wap-native :space-key="spaceKey" @aderror="handleError" @adclose="handleClose" @adclick="handleClick"></wap-native>
        </div>
    </div>
</template>

<style scoped lang="less">

</style>
